 /*
 * App Style
 *
 * The base style for the application.
 *
 */
 @charset "utf-8/index";

 @import '_base.scss';
 @import '_variables.scss';
 @import '_mixins.scss';
 @import '_typography.scss';

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

::selection {
  background: $pop; /* WebKit/Blink Browsers */
}

section {
  border-bottom: 1px solid white;
}

.slick-slider {
  .slick-arrow {
    height: 40px;
    width: 40px;
  }
  .slick-prev {
    left: -30px;
    transform: rotate(180deg);
    transform-origin: center 10px;
  }
  .slick-next {
    right: -30px;
  }
  .slick-next, .slick-prev {
    border-radius: 100%;
    z-index: 2;
  }
  .slick-prev:before, .slick-next:before {
    display: none;
  }
}

.rule-white {
  width: 100%;
  height: 1px;
  background: $white;
  margin: 20px 0;
  z-index: 2;
}

.rule-orange {
  width: 100%;
  height: 3px;
  background: $pop;
  margin: 20px 0;
}

.rule-grey {
  width: 100%;
  height: 11px;
  background: $grey-light;
  margin: 20px 0;
}

.text-arrow-link {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .05rem;
  cursor: pointer;
  vertical-align: top;
  @include smoothness(.2s);
  span {
    color: $pop;
    padding-left: 8px;
    margin-top: 4px;
    line-height: 24px;
    font-size: 24px;
    font-weight: lighter;
    @include smoothness(.2s);
  }
  &:hover {
    color: $pop;
    span {
      padding-left: 12px;
    }
  }
}

.button {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  letter-spacing: .025rem;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $white;
  background: $pop;
  border-radius: 20px;
  border: none;
  padding: .675em 2.4em;
  z-index: 2;
  margin-top: 20px;
  white-space: nowrap;
  @include smoothness(.2s);
  &:hover {
    transform: scale(1.05);
    color: #E37625;
    background: $white;
    box-shadow: 3px 3px 10px #00000052;
  }
  &:active {
    color: $white;
    background: $pop;
    transform: scale(1.02);
    box-shadow: none;
  }
}

.page-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  .subscribe {
    width: 100%;
    max-width: 1500px;
  }
}


.page-content-container {
  width: 100%;
  max-width: 1320px;
  padding: 0 40px;
  flex: 1;
  .rule-orange {
    margin: 44px 0;
  }
}

.page-content-header {
  @include smoothness(.2s);
}

.page-content-header-title {
  margin-bottom: 1rem;
}


p { 
  margin-block-start: 0;
  margin-block-end: 0;
}

.pc-section {
  padding: 60px 0;
}


body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fira Code",
  monospace;
}

.no-scroll {
  overflow: hidden;
}

.CookieConsent {
  align-items: baseline;
  background: $secondary;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 10px 20px;
  gap: 10px;
  button {
    background-color: $pop;
    color: $white;
    padding: 0.625em 2em;
    height: 40px;
    cursor: pointer;
    border-radius: 12px;
    margin: 0;
    &.cookie-consent-decline {
      background-color: transparent;
      border: 2px solid $pop;
      font-weight: 700;
      color: $pop;
      @include smoothness(.2s);
      &:hover {
        background-color: $pop;
        color: $white;
      }
    }
  }
}

.cookie-link {
  text-decoration: underline;
  &:hover {
    color: $pop;
    @include smoothness(.2s);
  }
}

.cookie-consent-buttons {
  display: flex;
  gap: 10px;
}


@media only screen and (max-width: 1000px) {
    .page-content-header {
      padding: 0 40px;
    }
}

@media only screen and (max-width: 920px) {
  .CookieConsent {
    padding: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .page-content-container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 480px) {
  .page-content-container {
    padding: 0 20px;
  }
  .page-content-header {
    padding: 0 20px;
  }
  .button {
    width: 100%;
  }
}